<template>
  <div class="security-page">
    <top-bar back backroute="profile" :title="$app.secure.clean($n.t('10.6'))" />
    <n-loader :loading="$var('load')" />
    <div class="container">
      <div class="wrap">

        <div class="item">
          <n-link :to="{name: ''}"></n-link>
          <div class="left">
            <div class="img"><n-icon icon="pin-lock" /></div><div class="title"><t name="14.1" /></div>
          </div>
          <div class="right">
            <c-radio :checked="usePinCode"
                     :value.sync="usePinCode" @change="edit('usePinCode', usePinCode)" />
          </div>
        </div>

        <div v-if="usePinCode" class="item">
          <n-link :to="{name: 'my.data'}"></n-link>
          <div class="left">
            <div class="img"><n-icon icon="imprint" /></div><div class="title"><t name="14.2" /></div>
          </div>
          <div class="right">
            <c-radio :checked="useTouchId"
                     :value.sync="useTouchId" @change="edit('useTouchId', useTouchId)" />
          </div>
        </div>


        <div v-if="usePinCode" class="item">
          <n-link :to="{name: 'change.pin'}"></n-link>
          <div class="left">
            <div class="img"><n-icon icon="lock" /></div><div class="title"><t name="14.3" /></div>
          </div>
          <div class="right"><n-icon icon="right-arrow" /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageSecurity',
  data() {
    return {
      user: $app.auth.user(),
      usePinCode: false,
      useTouchId: false,
    }
  },
  created() {
    $app.store.action('app.updateUser').finally(()=>{
      this.user = $app.auth.user()
    })
    this.useTouchId = this.user.useTouchId
    this.usePinCode = this.user.usePinCode
  },
  methods: {
    edit(field, value) {
      this.$var('load', true)
      $api.user.edit(this.user.id, { [field]: value, }).then(() => {
        $app.store.action('app.updateUser').finally(() => {
          this.user = $app.auth.user()
          console.log(this.user)
          console.log(localStorage.getItem('auth'))
        })
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.security-page {
  .wrap {
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 18px 0;
      border-bottom: 1px solid #D9D9D9;
      position: relative;

      .n-link{
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
      }

      .img{
        margin-right: 8px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .left{
        display: flex;
        align-items: center;
      }
    }

    .title{
      font-weight: 400;
      font-size: 18px;
      color: #102E4F;
    }
  }
}
</style>
